body {
  background: rgba(14, 13, 9, 1);
  min-height: 100vh;
}

/*NavBar*/
nav {
  background: #08111b;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  padding: 20px 100px;
  z-index: 10000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s;
}
.textLogo
{
  font-family: 'Times New Roman', serif;
  text-transform: uppercase;
  font-size: 33px;
  letter-spacing: 5px;
  font-weight: bold;
  background-image: linear-gradient(to right, #8a6645 0, #cb9b51 22%, #f6e27a 45%,
      #f6f2c0 50%, #f6e27a 55%, #cb9b51 78%, #8a6645 100%);
  color: transparent;
  -webkit-background-clip: text;
}
nav .linkLogo {
  text-decoration: none;
}

nav .navigation {
  position: relative;
  display: flex;
  padding-top: 1rem;
}

nav .navigation li {
  list-style: none;
  margin-left: 30px;
}

nav .navigation li a {
  text-decoration: none;
  color: #fff8e8;
  font-weight: 500;
  padding: 5px 10px;
  overflow: hidden;
  position: relative;
}

nav .navigation li a:hover {
  color: #000;
  background: linear-gradient(90deg, hsl(48, 100%, 50%) 0%,
      hsl(28, 100%, 54%, 100%));
  border-radius: 25px;
  border: #000 solid 1px;
  overflow: hidden;
  font-weight: 600;
}

nav .navigation li a:hover::before {
  content: '';
  width: 25px;
  height: 80px;
  background-color: hsla(48, 30%, 95%, .3);
  position: absolute;
  top: -1rem;
  left: 2.3rem;
  rotate: 30deg;
  z-index: 1;
}

nav .navigation li a:hover::after {
  content: '';
  width: 15px;
  height: 80px;
  background-color: hsla(48, 30%, 95%, .3);
  position: absolute;
  top: -1rem;
  left: 2.6rem;
  rotate: 30deg;
  z-index: 1;
}

.navbar-brand {
  font-family: 'Times New Roman', serif;
  text-transform: uppercase;
  font-size: 33px;
  letter-spacing: 5px;
  font-weight: bold;
  background-image: linear-gradient(to right, #8a6645 0, #cb9b51 22%, #f6e27a 45%,
      #f6f2c0 50%, #f6e27a 55%, #cb9b51 78%, #8a6645 100%);
  color: transparent;
  -webkit-background-clip: text;
}

.navbar-brand:hover {
  color: transparent;
}
/*boton Moneda*/
.brilloMoneda1,
.brilloMoneda2 {
  width: 8px;
  height: 120px;
  background-color: hsla(48, 30%, 95%, .3);
  rotate: 30deg;
  position: absolute;
  inset: 0;
  margin: auto;
  transition: left .6s cubic-bezier(.2, .5, .2, 1.2);
}

.brilloMoneda1::after {
  content: '';
  width: 26px;
  height: 100%;
  background-color: hsla(48, 30%, 95%, .3);
  position: absolute;
  top: -1rem;
  left: 1.25rem;
}

.brilloMoneda2::after {
  content: '';
  width: 40px;
  height: 100%;
  background-color: hsla(48, 30%, 95%, .3);
  position: absolute;
  top: -1rem;
  left: .8rem;
}
.nav-link:active,
.nav-link:focus {
  color: #000;
  background: linear-gradient(90deg, hsl(48, 100%, 50%) 0%,
      hsl(28, 100%, 54%, 100%));
  border-radius: 15px;
  border: #000 solid 1px;
  overflow: hidden;
  font-weight: 600;
}

.nav-link:active::after,
.nav-link:focus::after {
  content: '';
  width: 30px;
  height: 60px;
  background-color: hsla(48, 30%, 95%, .3);
  position: absolute;
  top: -.5rem;
  left: 2rem;
  rotate: 30deg;
  z-index: 1;
}

.nav-link:active::before,
.nav-link:focus::before {
  content: '';
  width: 20px;
  height: 60px;
  background-color: hsla(48, 30%, 95%, .3);
  position: absolute;
  top: -.5rem;
  left: 2.5rem;
  rotate: 30deg;
  z-index: 1;
}
/*Toggler */
.menuToggle {
  border: none;
  display: none;
}
.subMenuWrapAll
{
  position: absolute;
  top: 100%;
  left: 0px;
  right: 0px;
  background: #111;
  width: 100%;
  padding: 2rem 1rem;
  list-style: none;
}
.subMenuWrapAll #subMenuLink {
  font-family: cafe;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
  color: white;
  letter-spacing: 2px;
}
.subMenuWrapAll #subMenuLink:hover
{
  color: #000;
  background: linear-gradient(90deg, hsl(48, 100%, 50%) 0%,
      hsl(28, 100%, 54%, 100%));
  border-radius: 25px;
  border: #000 solid 1px;
  overflow: hidden;
  font-weight: 700;
  padding: .5rem;
}

.containerLogo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  position: absolute;
  bottom: -35px;
  left: 0px;
  z-index: 4;
}
.imgLogo {
  background: linear-gradient(180deg, #462523 0, #cb9b51 22%, #f6e27a 45%,
      #f6f2c0 50%, #f6e27a 55%, #cb9b51 78%, #462523 100%);
  text-align: center;
  padding: .5rem;
  border-radius: 5px;
  border: 2px solid black;
  width: 95px;
}
/*Banner*/
.banner {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(14, 13, 9, 1)),
    url('./images/fondos/banner.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 3rem;
}
.banner .coljuegos
{
  position: absolute;
  top: 90px;
  left: 20px;
  width: 200px;
}
.banner .coljuegos img{
  width: 100%;
}
.banner .coljuegos p
{
  text-align: center;
  font-family: cafe;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
}
.banner .iconoColjuegos
{
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 210px;
}
.banner .iconoColjuegos img
{
  width: 100%;
}
.banner .contenidoBanner {
  padding: 20px;
  background-color: #111;
  max-width: 500px;
  text-align: center;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: slideInFromLeft 0.5s ease-in-out;
}

.banner .contenidoBanner .contenedorImg img {
  margin-top: 40px;
  display: block;
  width: 300px;
  margin: auto;
}

.banner .contenidoBanner h1 {
  margin-top: 1rem;
  font-size: clamp(1.5rem, 1.8vw, 2rem);
}

.banner .contenidoBanner h5 {
  margin-top: 1rem;
  font-size: clamp(1rem, 1.2vw, 1.2rem);
  font-weight: normal;
  font-family: cafe;

}

/*BOTON DORADO ESTRELLADO*/
.containerBtn {
  position: relative;
  width: 250px;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.buton {
  position: relative;
  transition: transform .4s;
  text-decoration: none;
}

.btnContent {
  position: relative;
  background: linear-gradient(90deg, hsl(48, 100%, 50%) 0%, hsl(28, 100%, 54%, 100%));
  padding: 1rem;
  border-radius: 4rem;
  border: 3px solid black;
  color: black;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  column-gap: .5rem;
  overflow: hidden;
  z-index: 2;
}

.btnText {
  font-weight: 700;
  font-family: roboto;
  letter-spacing: 1px;
  position: relative;
  z-index: 3;
}

#geo {
  font-size: 1.5rem;
  position: relative;
}

.buton img {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition: transform .5s, opacity .5s;
  z-index: 3;
}

.star1 {
  width: 20px;
  top: -7px;
  left: -130px;
  transform: rotate(48deg) scale(.1);
}

.star2 {
  width: 40px;
  right: -10px;
  top: -4px;
  transform: rotate(48deg) scale(.1);
}

.circle1 {
  width: 8px;
  bottom: -2px;
  right: 34px;
  transform: scale(.1);
}

.circle2 {
  width: 8px;
  top: -6px;
  left: -100px;
  transform: scale(.1);
}

.triangle1 {
  width: 10px;
  top: -14px;
  right: 60px;
  transform: rotate(10deg) scale(.1);
}

.triangle2 {
  width: 18px;
  left: -80px;
  bottom: -16px;
  transform: rotate(-48deg) scale(.1);
}

.btnShadow {
  width: 100%;
  height: 100%;
  position: absolute;
  left: -7.5rem;
  top: 0;
  background: linear-gradient(90deg, hsl(48, 100%, 50%) 0%, hsl(28, 100%, 54%, 100%));
  padding: 1rem;
  border-radius: 4rem;
  border: 3px solid black;
  width: 230px;
  z-index: 1;
  transition: transform .3s;
}

.buton:hover .btnShadow {
  transform: translate(-.5rem, .5rem);
}

.buton:hover img {
  opacity: 1;
}

.buton:hover .star1 {
  transform: scale(1.1);
  transition-delay: .1s;
  filter: invert(100%);
}

.buton:hover .star2 {
  transform: scale(1.1);
  filter: invert(100%);
}

.buton:hover .circle1 {
  transform: translateY(10px) scale(1.1);
  transition-delay: .1s;
  filter: invert(100%);
}

.buton:hover .circle2 {
  transform: translate(20px, -8px) scale(1.1);
  transition-delay: .1s;
  filter: invert(100%);
}

.buton:hover .triangle1 {
  transform: scale(1.1);
  transition-delay: .1s;
  filter: invert(100%);
}

.buton:hover .triangle2 {
  filter: invert(100%);
  transform: rotate(-12deg) scale(1.1);

}

/*Nosotros*/
.fondoNosotros {
  background: linear-gradient(180deg, rgba(14, 13, 9, 1)0%, rgba(0, 0, 0, 0)50%, rgba(14, 13, 9, 1)100%), url('./images/fondos/fondoPrueba.jpg');
  background-size: auto;
  padding: 2rem 1rem;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bordeDorado {
  min-height: 100%;
  background: linear-gradient(135deg, #462523 0, #cb9b51 22%, #f6e27a 45%,
      #f6f2c0 50%, #f6e27a 55%, #cb9b51 78%, #462523 100%);
  border-radius: 16px;
  padding: 5px;
  position: relative;
}

.bordeDoradoDelgado {
  min-height: 100%;
  background: linear-gradient(135deg, #462523 0, #cb9b51 22%, #f6e27a 45%,
      #f6f2c0 50%, #f6e27a 55%, #cb9b51 78%, #462523 100%);
  padding: 3px 0px;
  position: relative;
}
.bordeDoradoBottom {
  height: 1px;
  min-width: 100%;
  background: linear-gradient(135deg, #462523 0, #cb9b51 22%, #f6e27a 45%,
      #f6f2c0 50%, #f6e27a 55%, #cb9b51 78%, #462523 100%);
      padding: 1px 0px 1px;
  position: absolute;
  left: 0px;
  bottom: -2px;
}
.containerNosotros {
  background-color: #071313;
  width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.containerNosotros .containerMision {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: row;
}

.containerNosotros .containerMision .containerTextMision {
  padding: 1rem;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.containerNosotros .containerMision .imgMision {
  width: 50%;
  height: 100%;
  background-image: url('./images/fondos/ruletta.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  border-top-right-radius: 16px;
}

.containerNosotros .containerMision .imgVision {
  width: 50%;
  height: 100%;
  background-image: url('./images/fondos/tragamonedas.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  border-end-start-radius: 16px;
}

.containerNosotros .containerMision .containerTextMision p {
  color: white;
  margin: 2rem;
  font-family: cafe;
  font-size: clamp(1.1rem, 1.5vw, 1.3rem);
  text-align: center;
}

.containerNosotros .containerMision .containerTextMision .imgIconosCasino {
  width: 60px;
  position: relative;
  rotate: 15deg;
}

/*GALERIA*/
.fondoGaleria {
  padding: 2rem 1rem;
  background: linear-gradient(rgba(14, 13, 9, 0.829), rgba(14, 13, 9, .829)), url('./images/fondos/fondoUbicaciones.jpeg');
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  padding: 1rem;
}

.textEntretenimiento {
  text-align: center;
  font-size: clamp(1.1rem, 1.2vw, 1.2rem);
  margin-top: 2rem;
  font-family: cafe;
  color: white;
  margin-inline: 1rem;
}

.imgIconosCasino {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  display: inline-block;
}

.galeria>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.galeria>div>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;

}

.galeria>div>img:hover {
  filter: opacity(.8);
}

.galeria {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-auto-rows: 150px;
  grid-auto-flow: dense;
  background: linear-gradient(135deg, #462523 0, #cb9b51 22%, #f6e27a 45%,
      #f6f2c0 50%, #f6e27a 55%, #cb9b51 78%, #462523 100%);
  padding: 5px;
  border-radius: 5px;
}

.galeria .wide {
  grid-column: span 2;
}

.galeria .tall {
  grid-column: span 2;
}

.galeria .big {
  grid-column: span 2;
  grid-row: span 2;
}

.containerJuegos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 4rem 1rem;
}

.containerJuegos .grapJuego {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.containerJuegos .grapJuego img {
  width: 140px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.containerJuegos .grapJuego img:hover {
  transform: rotateY(180deg);
}

.containerJuegos .grapJuego p {
  color: goldenrod;
  font-family: cafe;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  margin-top: .5rem;
  font-weight: 600;
}

/*SEDES*/
.containerUbicaciones {
  background: linear-gradient(180deg, rgba(14, 13, 9, 1)0%, rgba(0, 0, 0, 0)50%, rgba(14, 13, 9, 1)100%), url('./images/fondos/fondoPrueba.jpg');
  background-size: auto;
  padding: 2rem 1rem;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-position: center center;
  min-height: 100vh;
  padding: 1rem;
}

.contenedorDirecciones {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contenedorDirecciones h2 {
  margin: 1rem 0 2rem 0;
}

.contenedorDirecciones p {
  color: white;
  text-align: center;
  font-size: clamp(1.1rem, 1.3vw, 1.3rem);
}

.containerCard {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  flex-wrap: wrap;
}

.containerCard .boxCard {
  position: relative;
  width: 310px;
  height: 450px;
  color: white;
  background: #071313;
  transition: 0.5s;
  border-radius: 10px;
}

.marginCard {
  margin: 20px 30px;
}

.containerCard .boxCard .imgMap {
  width: 100%;
  height: 140px;
  background-color: #cb9b51;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}

.containerCard .boxCard .contentCard h5 {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-family: 'Times New Roman', serif;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: bold;
  background-image: linear-gradient(to right, #462523 0, #cb9b51 22%, #f6e27a 45%,
      #f6f2c0 50%, #f6e27a 55%, #cb9b51 78%, #462523 100%);
  color: transparent;
  -webkit-background-clip: text;
}

.containerCard .boxCard .contentCard .textDireccion {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.containerCard .boxCard .contentCard .textDireccion i {
  background-image: linear-gradient(to right, #462523 0, #cb9b51 22%, #f6e27a 45%,
      #f6f2c0 50%, #f6e27a 55%, #cb9b51 78%, #462523 100%);
  color: transparent;
  -webkit-background-clip: text;
  font-size: 1.5rem;
}

.containerCard .boxCard .contentCard p {
  font-family: cafe;
  margin-bottom: -5px;
  font-size: clamp(1rem, 1.2vw, 1.1rem);

}

.containerCard .boxCard .contentCard .textDireccion .spanDireccion {
  text-align: center;
  font-family: titulo;
}

.containerButon {
  position: absolute;
  bottom: 10px;
  left: 75.5px;
}

.btnContent {
  position: relative;
  background: linear-gradient(90deg, hsl(48, 100%, 50%) 0%, hsl(28, 100%, 54%, 100%));
  padding: .5rem 2rem;
  border-radius: 4rem;
  border: 2px solid black;
  color: black;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  column-gap: .5rem;
}

.btnText {
  font-weight: 700;
  font-family: roboto;
  letter-spacing: 1px;
  position: relative;
  z-index: 3;
}

/*FINANZAS*/
.containerEstados {
  background-color: #08111b;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 1rem;
}

.boxText {
  width: 100%;
  background-color: #000;
  padding: .5rem;
  margin-bottom: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerEstados .containerBotones {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
}

.containerEstados .containerBotones h5 {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-family: 'Times New Roman', serif;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: bold;
  background-image: linear-gradient(to right, #633431 0, #cb9b51 22%, #f6e27a 45%, #f6f2c0 50%, #f6e27a 55%, #cb9b51 78%, #633431 100%);
  color: transparent;
  -webkit-background-clip: text;
}

.containerEstados .containerBotones .boxBotones {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  column-gap: 3rem;
  margin-top: 2rem;

}

.containerEstados .containerBotones .boxBotones .cajaInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-family: cafe;
  color: white;
}

.containerEstados .boxBotones .btnContent {
  margin-bottom: 1rem;
  width: 150px;
}

/*FOOTER*/
.footer {
  background-image: linear-gradient(to right, #cb9b51 0%, #f6e27a 45%,
      #f6f2c0 50%, #f6e27a 55%, #cb9b51 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer span {
  font-family: cafe;
  font-weight: 600;
  padding: .5rem;
  text-align: center;
  font-size: clamp(.9rem, 1vw ,1rem);
}

.footer span a {
  text-decoration: none;
  color: #000000;
  font-family: Cream;
  letter-spacing: 2px;
  font-size: 1.4rem;
  margin-left: 5px;
  font-weight: 500;
}
/*Loading*/
.containerLoading
{
  background: #151515;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading{
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.progress-bar
{
  width: 406px;
  height: 21px;
  background: #111;
  border-radius: 13px;
  padding: 3px;
  box-sizing: border-box;
  margin-top: 1rem;
}
.progress
{
  width: 200px;
  height: 15px;
  background: linear-gradient(135deg, #462523 0, #cb9b51 22%, #f6e27a 45%,
  #f6f2c0 50%, #f6e27a 55%, #cb9b51 78%, #462523 100%);
  border-radius: 13px;
  animation: fillProgress 11s ease forwards;
}
@keyframes fillProgress {
  from {
      width: 0%;
  }
  to {
      width: 100%;
  }
}
/*RESPONSIVE*/
@media only screen and (max-width: 1367px) {
  .galeria {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    grid-auto-rows: 150px;
  }

  .containerJuegos .grapJuego img {
    width: 105px;
  }
}

@media only screen and (max-width: 1003px) {
  .galeria {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-auto-rows: 100px;
  }
}

@media only screen and (max-width: 900px) {
  nav {
    padding: 1px 20px;
    height: 70px;
  }

  nav .navigation {
    display: none;
  }

  .menuToggle {
    display: block;
    position: relative;
    width: 50px !important;
    height: 50px;
    background: linear-gradient(90deg, hsl(48, 100%, 50%) 0%,
        hsl(28, 100%, 54%, 100%));
    border: gold solid 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s ease;
    overflow: hidden;
  }

  .menuToggle:hover {
    transform: rotateY(180deg);
  }

  .menuToggle i {
    color: #000000;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 2;
  }
}

@media only screen and (max-width: 768px) {
  .containerNosotros .containerMision:nth-child(1) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .containerNosotros .containerMision:nth-child(2) {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
  }

  .containerNosotros .containerMision .containerTextMision {
    width: 100%;
  }

  .containerNosotros .containerMision .imgMision {
    width: 100%;
    height: 300px;
    border-bottom-left-radius: 16px;
  }

  .containerNosotros .containerMision .imgVision {
    width: 100%;
    height: 300px;
    border-bottom-right-radius: 16px;
  }

  .fondoNosotros {
    background: url('./images/fondos/fondoBody.jpg');
    background-size: auto;
  }

  .banner {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(14, 13, 9, 1)),
      url('./images/fondos/bannerPrueba.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }
  .banner .contenidoBanner .contenedorImg img 
  {
    width: 250px;
  }
  .containerJuegos .grapJuego img {
    width: 95px;
  }

  .containerEstados .containerBotones .boxBotones {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

}

@media only screen and (max-width: 679px) {
  .galeria {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    grid-auto-rows: 100px;
  }

  .galeria .big {
    grid-column: span 2;
    grid-row: span 1;
  }

  .galeria .wide {
    grid-column: span 1;
  }

  .containerJuegos {
    padding: 2rem 1rem;
  }
  .banner .coljuegos
  {
    left: 0px;
    top: 75px;
    width: 140px;
    z-index: 9999;
  }
  .banner .iconoColjuegos
  {
    width: 100px;
    bottom: 5px;
    right: 0px;
  }
}

@media only screen and (max-width: 576px) {

  .navbar-brand {
    font-size: 23px;
  }

  .banner .contenidoBanner {
    width: 340px;
  }

  .containerNosotros .containerMision .containerTextMision p {
    margin: 1rem;
  }

  .containerJuegos .grapJuego img {
    width: 75px;
  }

  .marginCard {
    margin: 20px 20px;
  }
  .textLogo
  {
    font-size: 17px;
  }
}

@media only screen and (max-width: 540px) {
  .galeria {
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    grid-auto-rows: 100px;
  }
}

/* FONTS */

@font-face {
  font-family: 'Cafe';
  src: url('./font/cafe.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cream';
  src: url('./font//cream_cake.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'titulo';
  src: url('./font/titulo.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'roboto';
  src: url('./font/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}